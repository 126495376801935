import { axiosJwt } from '@/shared/auth/axiosJwt';

const apiBase = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKOUT_PORT}${process.env.VUE_APP_API_PATH}/checkout`;

export default {
    obterProdutosCheckouts() {
        return axiosJwt.get(`${apiBase}/produtocheckout`);
    },

    obterCheckoutId(checkoutId) {
        return axiosJwt.get(`${apiBase}/produtocheckout/produto_checkout/${checkoutId}`);
    },
};
