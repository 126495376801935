<template>
    <painel titulo="Links de Pagamentos" :refreshFunction="atualizar" v-if="mostrarListagem">
        <tabela
            :data="links"
            dataKey="checkoutId"
            :globalFilterFields="[
                'linkPagamento',
                'situacao',
                'produto.valor',
                'produto.valorFormatado',
                'dataCadastro',
                'dataCadastroFormatada',
                'nomeUsuarioCadastro',
                'situacaoDescricao',
                'cpf',
                'cpfFormatado',
                'nome',
                'produtoCheckoutId',
            ]"
            stateKey="dt-links"
        >
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <div class="flex">
                            <font-awesome-icon
                                icon="info-circle"
                                @click="toDetalhar(slotProps.data)"
                                size="lg"
                                class="icon-button"
                                title="Detalhar"
                                style="color: #0772b3"
                            />
                        </div>
                    </template>
                </Column>
                <column field="linkPagamento" header="Link" :sortable="true">
                    <template #body="slotProps">
                        <p style="cursor: pointer">{{ slotProps.data?.linkPagamento }}</p>
                    </template>
                </column>
                <column field="nome" header="Comprador" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.nome }}</p>
                    </template>
                </column>
                <!-- <column field="cpf" header="Cpf" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ $toCpf(slotProps.data.cpf) }}</p>
                    </template>
                </column> -->
                <column field="situacaoDescricao" header="Situação" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data?.situacaoDescricao"></status>
                    </template>
                </column>
                <column field="integrado" header="Integrado" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.integrado }}</p>
                    </template>
                </column>
                <column field="nomeUsuarioCadastro" header="Quem Cadastrou" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.nomeUsuarioCadastro }}</p>
                    </template>
                </column>
                <column field="dataCadastroFormatada" header="Data de Criação" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.dataCadastroFormatada }}</p>
                    </template>
                </column>
                <column field="produto.valor" header="Valor Total" :sortable="true">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.produto?.valorFormatado }}</p>
                    </template>
                </column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import Services from './service';
export default {
    data() {
        return {
            links: [],
            error: null,
        };
    },

    mounted() {
        this.buscaLinks();
    },

    methods: {
        buscaLinks() {
            this.$store.dispatch('addRequest');
            Services.obterProdutosCheckouts()
                .then((response) => {
                    this.links = response.data;
                })
                .catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao buscar links de pagamentos',
                        life: 3000,
                    });
                    this.error = error;
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        atualizar() {
            this.buscaLinks();
        },

        toDetalhar(checkout) {
            this.$router.push({
                name: 'links-pagamentos-detalhes',
                params: {
                    id: checkout.produtoCheckoutId,
                },
            });
        },
    },

    computed: {
        mostrarListagem() {
            return this.$route.name == 'links-pagamentos';
        },
    },

    watch: {
        $route(to) {
            if (to.name == 'links-pagamentos') {
                if (this.$store.getters.atualizar) {
                    this.buscaLinks();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
